<template>
  <el-row style="margin: 4px">
    <el-col :md="12" class="md-12-print" v-for="v in [1, 2]" :key="v">
      <el-card class="print-border" v-loading="isLoading" shadow="never">
        <template #header>
          <el-row type="flex" justify="center" class="mt--5">
            <el-image
              style="width: 350px; height: 100px"
              :src="'/images/logo.png'"
              :fit="'contain'"
            ></el-image>
          </el-row>
          <el-row type="flex" justify="center">
            <small class="small-font"
              >Rua Cel Alcebiades de Miranda, Nº 639. Bairro Boa Vista, Ponta
              Grossa - PR. CEP 84.073-000</small
            >
          </el-row>
          <el-row :gutter="4" type="flex" justify="center">
            <i class="el-icon-message"></i>
            <small class="small-font">donauagronegocios@gmail.com</small>
            <i class="el-icon-phone" style="margin-left: 8px"></i>
            <small class="small-font"> (042) 3227-1744</small>
          </el-row>
          <el-row :gutter="4" type="flex" justify="center"> </el-row>
          <el-row type="flex" justify="space-between" align="center">
            <el-col
              ><el-row type="flex" justify="center"
                ><h4 class="m-0">
                  Autorização para Abastecimento de Veículo
                </h4></el-row
              ></el-col
            >
          </el-row>
        </template>
        <el-descriptions direction="vertical" :column="2" :size="size" border>
          <el-descriptions-item>
            <template #label> Fornecedor: </template>
            {{ Data.provider?.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Ordem: </template>
            {{ Data.code }}/{{ Data.year_code }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Placa: </template>
            {{ Data.car?.pane }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Produtos: </template>
            {{ ProductsListed }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Kilometragem do Veículo: </template>
            {{ numberFormat.format(Number(Data.odometer) || 0) }} km.
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Motorista: </template>
            {{ Data.driver?.employee?.name }}
          </el-descriptions-item>
        </el-descriptions>

        <el-divider class="mb-1"></el-divider>

        <h4 class="m-0">
          Autorizo o abastecimento do veículo acima descrito para
          {{
            Data.amount
              ? numberFormat.format(Number(Data.amount)) + " litros."
              : "completar o tanque."
          }}
        </h4>
        <h6 class="m-0">Não abastecer após expirado o prazo de validade.</h6>

        <el-divider class="mb-1"></el-divider>

        <el-row>
          <el-col :md="12" class="md-12-print">
            <el-row type="flex" justify="space-between">
              <h5 class="text-muted m-0">Data:</h5>
              <h5 class="m-0">
                {{
                  dateFormatter.format(new Date(Data.created_at || Date.now()))
                }}
              </h5>
            </el-row>
            <el-row type="flex" justify="space-between" align="center">
              <h5 class="text-muted m-0">Validade:</h5>
              <h5 class="m-0">
                {{
                  dateFormatter.format(new Date(Data.expires_at || Date.now()))
                }}
              </h5>
            </el-row>

            <el-card class="print-border" shadow="never" style="height: 150px">
              <el-row type="flex" justify="center" style="margin-top: 65px">
                <el-col align="center">
                  <h5 class="m-0">___________________________</h5>
                  <h6 class="m-0">ASSINATURA DO MOTORISTA</h6>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :md="12" class="md-12-print" v-if="v === 1">
            <el-row type="flex" justify="center" align="center">
              <vue-qr-code
                :value="Data.execution?.uid"
                :options="{ width: 180 }"
              ></vue-qr-code>
            </el-row>
          </el-col>
        </el-row>
        <small v-if="Obs">Observação: {{ Obs }}</small>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
//import Moment from "moment";
//import { ElNotification } from "element-plus";
import VueQrCode from "@chenfengyuan/vue-qrcode";
export default {
  name: "BillsPage",
  data: () => ({
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormat: new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      style: "decimal",
    }),
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
  }),
  components: { VueQrCode },
  computed: {
    Data() {
      return JSON.parse(this.$route?.query?.data || "{}");
    },
    Provider() {
      return this.$route.params?.provider || {};
    },
    Obs() {
      return this.Data?.obs?.slice(0, 50);
    },
    ProductsListed() {
      const _ = [];
      if (this.Data.fuel) _.push(this.Data.fuel_formatted);
      if (this.Data.fuel2) _.push(this.Data.fuel2_formatted);
      if (this.Data.lubrification) _.push(this.Data.lubrification_formatted);
      return _.join();
    },
  },

  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
  },
};
</script>
<style>
body {
  -webkit-print-color-adjust: exact;
}
.el-card {
  width: 100%;
}
.mt--5 {
  margin-top: -36px !important;
}
.m-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.p-0 {
  padding: 0px !important;
}
.text-red {
  color: red;
}
.m-0 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.mb-1 {
  margin-bottom: 2px !important;
}
.text-center {
  text-align: center !important;
}
@media print {
  table.el-table__body {
    width: auto !important;
  }
  table.el-table__header {
    width: auto !important;
  }
  div.md-12-print {
    max-width: 49% !important;
  }
  div.md-8-print {
    max-width: 33% !important;
  }
}
.cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  width: 100%;
  justify-content: center;
}
.print-border {
  border: 2px solid black !important;
}
.small-font {
  font-size: 11px;
}
.el-descriptions__label {
  font-weight: 700 !important;
  color: black !important;
}
</style>